import styled from "styled-components";
import { useTranslation, Trans } from "react-i18next";
import { Row, Col } from 'antd';
import useMediaQuery from "hooks/useMediaQuery";
import { Button, SectionContainer, ContentContainer, HTwo, Paragraph } from "components";
import customize from "assets/images/customize2.png";

const Customize = () => {

  const { t } = useTranslation()

  return (
    <StyledSectionContainer id="demo">
      <ContentContainer>
        <StyledRow>
          <ImgCol xs={0} sm={12}>
          </ImgCol>
          <StyledImg src={customize} alt="Three phone screens with colors" />
          <ImgCol xs={0} sm={12}>
          </ImgCol>
          <StyledCol xs={24} sm={12}>
            <HTwo><Trans i18nKey="customize.header" components={[<span />]} /></HTwo>
            <Paragraph>{t("customize.description")}</Paragraph>
            <div>
              <Button
                type="primary"
                size="large"
                href="https://weavertechnologiesbv.pipedrive.com/scheduler/eKLAQ8f3/demo-f5-scan"
                target="_blank"
              >{t("globals.scheduleDemo")}</Button>
            </div>
          </StyledCol>
        </StyledRow>
      </ContentContainer>
    </StyledSectionContainer>
  )
}


const StyledCol = styled(Col)`
  justify-content: center;
  display: flex;
  flex-direction: column;
  position: static;
  padding: 30px;
  z-index: 1;
  & ${Button} {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    margin-top: 20px;
  }
`;
const StyledRow = styled(Row)`
  width: 100%;
  padding: 100px 0;
  /* min-height: 50vh; */
`;
const StyledSectionContainer = styled(SectionContainer)`
  padding: 0;
  position: relative;
  & ${HTwo} {
    box-sizing: border-box;
  }
  text-align: left;
  flex-direction: column;
  @media(max-width: 1200px) {
    padding: 0px;
    padding-bottom: 0;
    padding-top: 0px;
    &:before {
      content: "";
      position: absolute;
      top: -16%; right: -16%; height: 133%; width: 133%;
      background: radial-gradient(circle at 75% 50%, var(--faded-gradient-color), transparent 60%);
    }
  }
`;
const StyledImg = styled.img`
  position: absolute;
  right: 0;
  height: 100%;
  object-fit: contain;
  z-index: 0;
  @media(max-width: 576px) {
    display: none;
  }
  @media(max-width: 992px) {
    position: absolute;
    height: unset;
    width: 50%;
    max-height: 50vh;
    right: 0%;
    bottom: 0;
    object-fit: cover;
    object-position: top;
  }
`;
const ImgCol = styled(Col)`
  display: flex;
  justify-content: end;
  position: static;
  height: 0;
  &:before {
    content: "";
    position: absolute;
    top: 0%; right: 0%; height: 100%; width: 100%;
    background: radial-gradient(circle at 100% 50%, var(--faded-gradient-color), transparent 45%);
  }
`;

export default Customize;
